import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'addEditInventoryItems',
  components: {
    DatePicker
  },
  props: ['invItemsRowDetails'],
  data() {
    return {
      showValueSetModal: false,
      loader: false,
      unsubscribe: null,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      editMode: false,
      enabled: true,
      purchasingEnabled: false,
      shippable: false,
      serialControl: false,
      childLotControl: false,
      lotControl: false,
      customerOrder: false,
      stockEnabled: false,
      itemCode: null,
      inventoryOrg: {
        value: null,
        text: null
      },
      startDateActive: null,
      endDateActive: null,
      start_date_end_date: null,
      description: null,
      itemType: {
        value: null,
        text: null
      },
      primaryUom: {
        value: null,
        text: null
      },
      secondaryUom: {
        value: null,
        text: null
      },
      weight: null,
      weightUom: {
        value: null,
        text: null
      },
      volume: null,
      volumeUom: {
        value: null,
        text: null
      },
      invItemId: null,
      uomType: null,
      uom: null,
      parent_value_set_id: null
    };
  },
  mounted() {
    if (this.invItemsRowDetails) {
      this.fillRecord();
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditInventoryItems();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    fillRecord() {
      this.itemCode = this.invItemsRowDetails.item_code;
      this.inventoryOrg.text = this.invItemsRowDetails.org_name;
      this.startDateActive = this.invItemsRowDetails.start_date;
      this.endDateActive = this.invItemsRowDetails.end_date;
      this.description = this.invItemsRowDetails.description;
      this.itemType.text = this.invItemsRowDetails.item_type_meaning;
      this.primaryUom.text = this.invItemsRowDetails.primary_code_meaning;
      this.secondaryUom.text = this.invItemsRowDetails.secondary_code_meaning;
      this.weight = this.invItemsRowDetails.unit_weight;
      this.weightUom.text = this.invItemsRowDetails.weight_code_meaning;
      this.volume = this.invItemsRowDetails.unit_volume;
      this.volumeUom.text = this.invItemsRowDetails.volume_code_meaning;
      this.invItemId = this.invItemsRowDetails.inventory_item_id;
      this.enabled = this.invItemsRowDetails.enabled_flag ? true : false;
      this.purchasingEnabled = this.invItemsRowDetails.purchasing_item_flag
        ? true
        : false;
      this.shippable = this.invItemsRowDetails.shippable_item_flag
        ? true
        : false;
      this.serialControl = this.invItemsRowDetails.serial_number_control_flag
        ? true
        : false;
      this.childLotControl = this.invItemsRowDetails.child_lot_control_flag
        ? true
        : false;
      this.lotControl = this.invItemsRowDetails.lot_control_flag ? true : false;
      this.customerOrder = this.invItemsRowDetails.customer_order_flag
        ? true
        : false;
      this.stockEnabled = this.invItemsRowDetails.stock_enabled_flag
        ? true
        : false;
      this.serialControl = this.invItemsRowDetails.serial_number_control_flag
        ? true
        : false;
    },
    addEditInventoryItems() {
      const payload = {
        inventory_item_id: this.invItemId,
        org_id: this.org_id,
        enabled_flag: this.enabled,
        start_date: commonHelper.formattedDate(this.start_date_end_date[0]),
        end_date: commonHelper.formattedDate(this.start_date_end_date[1]),
        item_code: this.itemCode,
        description: this.description,
        item_type: this.itemType.value,
        purchasing_item_flag: this.purchasingEnabled,
        shippable_item_flag: this.shippable,
        customer_order_flag: this.customerOrder,
        stock_enabled_flag: this.stockEnabled,
        lot_control_flag: this.lotControl,
        child_lot_control_flag: this.childLotControl,
        serial_number_control_flag: this.serialControl,
        unit_weight: this.weight,
        weight_code: this.weightUom.value,
        unit_volume: this.volume,
        volume_code: this.volumeUom.value,
        primary_code: this.primaryUom.value,
        secondary_code: this.secondaryUom.value
      };
      this.loader = true;
      this.$store
        .dispatch('invAdmin/addEditInventoryItems', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    openValueSetModal(vsetCode, uomType, uom) {
      // To Open Value Set Modal
      this.vsetCode = vsetCode;

      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.INV_MASTER_ORG) {
        this.parent_value_set_id = 'Y';
      }
      this.uomType = uomType;
      this.uom = uom;
      this.showValueSetModal = true;
      if (this.uomClassObj) {
        this.parent_value_set_id = this.uomClassObj.value_set_dtl_id;
      }
      this.uomClassObj = null;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    opendDependentUom(vsetCode, uomType, uom) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          this.uomClassObj = null;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === uomType
            );
            if (findTimeObj) {
              this.uomClassObj = findTimeObj;
              this.openValueSetModal('UOM', uomType, uom);
            }
          }
        });
    },
    closeValueSetModal() {
      // To Close Value Set Modal
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'ITEM_TYPE') {
        this.itemType.value = item.value_code;
        this.itemType.text = item.value_meaning;
        if (this.itemType.value === 'Inventory') {
          this.stockEnabled = true;
        } else if (this.itemType.value === 'Expense') {
          this.stockEnabled = false;
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.INV_MASTER_ORG) {
        this.inventoryOrg.value = item.org_id;
        this.inventoryOrg.text = item.org_name;
      } else if (this.vsetCode === 'UOM') {
        if (this.uom === 'primaryUom') {
          this.primaryUom.value = item.value_code;
          this.primaryUom.text = item.value_meaning;
        } else if (this.uom === 'secondaryUom') {
          this.secondaryUom.value = item.value_code;
          this.secondaryUom.text = item.value_meaning;
        }
      }
      if (this.vsetCode === 'SCHEDULE_STATUS_VSET') {
        this.lease_status_meaning = item.value_meaning;
        this.lease_status = item.value_code;
      } else if (this.vsetCode === 'UOM') {
        if (this.uomType === 'WEIGHT') {
          this.weightUom.value = item.value_code;
          this.weightUom.text = item.value_meaning;
        } else if (this.uomType === 'VOL') {
          this.volumeUom.value = item.value_code;
          this.volumeUom.text = item.value_meaning;
        }
      }
      this.showValueSetModal = false;
    },
    lotControlChange() {
      this.childLotControl = false;
    },
    setDate(dateRange) {
      this.start_date_end_date = commonHelper.setFutureDate(dateRange);
    },
    clearVsetValues(vsetCode,uomType,uom){
      if (vsetCode === 'ITEM_TYPE') {
        this.itemType.value = null;
        this.itemType.text = null;
        
      } else if (vsetCode === 'INV_MASTER_ORG') {
        this.inventoryOrg.value = null;
        this.inventoryOrg.text = null;
      }else if (vsetCode === 'UOM') {
        if (uom === 'primaryUom') {
          this.primaryUom.value = null;
          this.primaryUom.text = null;
        } else if (uom === 'secondaryUom') {
          this.secondaryUom.value = null;
          this.secondaryUom.text = null;
        }
      }
      if (vsetCode === 'SCHEDULE_STATUS_VSET') {
        this.lease_status_meaning = null;
        this.lease_status = null;
      } else if (vsetCode === 'UOM_CLASS') {
        if (uomType === 'WEIGHT') {
          this.weightUom.value = null;
          this.weightUom.text = null;
        } else if (uomType === 'VOL') {
          this.volumeUom.value = null;
          this.volumeUom.text = null;
        }
      }
  }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
