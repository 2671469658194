import appStrings from '@/app/utility/string.utility';
export default {
    name: 'assignCategory',
    components: {
    },
    props: ['mappingInvDetails'],
    data() {
        return {
            payload: null,
            unsubscribe: null,
            isSuccess: false,
            showAlert: false,
            responseMsg: '',
            // org_id: null,
            loader: false,
            editMode: false,
            assignData: [
                {
                    category: null,
                    category_type: null
                }
            ],
            assignFields: [
                {
                    key: 'category_type'
                },
                {
                    key: 'category'
                },
                {
                    key: 'add'
                },
                {
                    key: 'remove'
                }
            ]
        };
    },
    mounted() {
        this.getAssignCategoryList();
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'shared/setActionName') {
                const actionName = state.shared.actionName;
                if (actionName === 'save' && this.editMode) {
                    this.addEditAssignCategory();
                }
                if (actionName === 'update') {
                    this.editMode = true;
                }
            }
        });
    },
    methods: {
        getAssignCategoryList() {
            const payload = {
                org_id: this.mappingInvDetails.org_id,
                inventory_item_id: this.mappingInvDetails.inventory_item_id
            }
            this.loader = true;
            this.$store
                .dispatch('invAdmin/getAssignCategoryList', payload)
                .then(response => {
                    this.loader = false;
                    if (response.status === 200) {
                        this.assignData = response.data.data;
                        this.totalRows = response.data.data.total_elements;
                    }
                })
                .catch(() => {
                    this.loader = false;
                });
        },
        addEditAssignCategory() {
            const assignCategory = this.assignData.map(elem => {
                return {
                    item_category_id: elem.item_category_id,
                    category_id: elem.category_id,
                    category_type_id: elem.category_type_id
                };
            });
            const payload = {
                inventory_item_id: this.mappingInvDetails.inventory_item_id,
                org_id: this.mappingInvDetails.org_id,
                data: assignCategory ? assignCategory : null
            };
            this.loader = true;
            this.$store
                .dispatch('invAdmin/addEditAssignCategory', payload)
                .then(response => {
                    this.loader = false;
                    this.showAlert = true;
                    if (response.status === 200) {
                        this.editMode = false;
                        this.isSuccess = true;
                        this.responseMsg = response.data.message;
                    } else {
                        this.isSuccess = false;
                        this.responseMsg = response.response.data.message;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    this.showAlert = true;
                    this.isSuccess = false;
                    this.responseMsg = appStrings.autoFailedMsg;
                });
        },
        addNewRow() {
            if (this.editMode) {
                this.assignData.push({
                    category: null,
                    category_type: null
                });
            }
        },
        removeRow(index) {
            if (this.editMode) {
                this.assignData.splice(index, 1);
            }
        },
    },
    beforeDestroy() {
        this.unsubscribe();
    }
};