import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import addEditInventoryItems from '../inventoryItems/addEditInventoryItems';
import MappingInventoryItems from './mappingInventoryItems';
import assign from './assign';
export default {
  name: 'inventoryItems',
  components: {
    DatePicker,
    addEditInventoryItems,
    MappingInventoryItems,
    assign
  },
  // props: ['mappingInvDetails'],
  watch: {
    currentPage: function() {
      this.getInvItemsGrid();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getInvItemsGrid();
    }
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      loader: false,
      editMode: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      invItemsRowDetails: null,
      showModal: false,
      showAssignModal: false,
      mappingInvDetails: null,
      module: null,
      itemCode: null,
      inventoryOrg: {
        value: null,
        text: null
      },
      parent_value_set_id: null,
      showValueSetModal: false,
      showinvItemModal: false,
      invItemsData: [],
      invItemsFields: [
        {
          key: 'inventory_item_id'
        },
        {
          key: 'item_code'
        },
        {
          key: 'org_name',
          label: 'Inventory Org'
        },
        {
          key: 'enabled_flag',
          label: 'Enabled'
        },
        {
          key: 'start_date',
          label: 'Start Date Active'
        },
        {
          key: 'end_date',
          label: 'End Date Active'
        },
        {
          key: 'description'
        },
        {
          key: 'item_type_meaning',
          label: 'Item Type'
        },
        {
          key: 'purchasing_item_flag',
          label: 'Purchasing Enabled'
        },
        {
          key: 'shippable_item_flag',
          label: 'Shippable'
        },
        {
          key: 'customer_order_flag',
          label: 'Customer Order'
        },
        {
          key: 'stock_enabled_flag',
          label: 'Stock Enabled'
        },
        {
          key: 'lot_control_flag',
          label: 'Lot Control'
        },
        {
          key: 'child_lot_control_flag',
          label: 'Child Lot Control'
        },
        {
          key: 'serial_number_control_flag',
          label: 'Serial Control'
        },
        {
          key: 'primary_code_meaning',
          label: 'Primary UOM'
        },
        {
          key: 'secondary_code_meaning',
          label: 'Secondary UOM'
        },
        {
          key: 'unit_weight',
          label: 'Weight'
        },
        {
          key: 'weight_code_meaning',
          label: 'Weight UOM'
        },
        {
          key: 'unit_volume',
          label: 'Volume'
        },
        {
          key: 'volume_code_meaning',
          label: 'Volume UOM'
        },
        {
          key: 'map',
          label: 'Inventory Org'
        },
        {
          key: 'category'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showinvItemModal = true;
          this.invItemsRowDetails = null;
        }
      }
    });
  },
  methods: {
    getInvItemsGrid() {
      // Get API for Inventory Item
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        org_id: this.orgId,
        item_code: this.itemCode
      };
      this.loader = true;
      this.$store
        .dispatch('invAdmin/getInvItemsGridList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.invItemsData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideInvItemModal(flag) {
      this.showinvItemModal = flag;
    },
    rowSelected(item) {
      this.invItemsRowDetails = item; // For Props
      this.showHideInvItemModal(true); // For Open Child Modal
    },
    openValueSetModal(vsetCode) {
      // To Open Value Set Modal
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.INV_MASTER_ORG) {
        this.parent_value_set_id = 'Y';
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      // To Close Value Set Modal
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      // To Selected Value from value set modal
      if (this.vsetCode === appStrings.VALUESETTYPE.INV_MASTER_ORG) {
        this.inventoryOrg.value = item.org_id;
        this.inventoryOrg.text = item.org_name;
      }
    },
    clearFilters() {
      this.inventoryOrg = {
        value: null,
        text: null
      };
      this.itemCode = null;
      this.totalRows = null;
      this.currentPage = 1;
      this.invItemsData = [];
    },
    openShowModal(flag, items) {
      this.showModal = flag;
      this.mappingInvDetails = items;
    },
    assign() {
      this.showAssignModal = false;
    },
    openAssignModal(flag, items) {
      this.showAssignModal = flag;
      this.mappingInvDetails = items;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'INV_MASTER_ORG') {
        this.inventoryOrg.value = null;
        this.inventoryOrg.text = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    clearTimeout(this.setTimeVsetCode);
  }
};
