import appStrings from '@/app/utility/string.utility';
export default {
    name: 'mappingInventoryItems',
    components: {
    },
    props: ['mappingInvDetails'],
    data() {
        return {
            payload: null,
            unsubscribe: null,
            isSuccess: false,
            showAlert: false,
            responseMsg: '',
            assigned: false,
            org_id: null,
            inventoryOrg: '',
            loader: false,
            editMode: false,
            mappingInventoryItemsData: [],
            mappingInventoryItemsFields: [
                {
                    key: 'org_name',
                    label: 'Inventory Org'
                },
                {
                    key: 'description'
                },
                {
                    key: 'assigned'
                }
            ]
        };
    },
    mounted() {
        this.getMappingInvItemDetails();
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'shared/setActionName') {
                const actionName = state.shared.actionName;
                if (actionName === 'save' && this.editMode) {
                    this.addEditMappingInvItemsById();
                }
                if (actionName === 'update') {
                    this.editMode = true;
                }
            }
        });
    },
    methods: {
        getMappingInvItemDetails() {
            const payload = {
                org_name: this.inventoryOrg,
                inventoryItemId: this.mappingInvDetails.inventory_item_id
            }
            this.loader = true;
            this.$store
                .dispatch('invAdmin/getMappingInvItemById', payload)
                .then(response => {
                    this.loader = false;
                    if (response.status === 200) {
                        this.mappingInventoryItemsData = response.data.data;
                        this.totalRows = response.data.data.total_elements;
                    }
                })
                .catch(() => {
                    this.loader = false;
                });
        },
        addEditMappingInvItemsById() {
            const mappingInventoryItems = this.mappingInventoryItemsData.map(elem => {
                return {
                    assigned: elem.assigned,
                    org_id: elem.org_id,
                };
            });
            const payload = {
                inventory_item_id:this.mappingInvDetails.inventory_item_id,
                mappingItems: mappingInventoryItems ? mappingInventoryItems : null
            };
            this.loader = true;
            this.$store
                .dispatch('invAdmin/addEditMappingInvItemsById', payload)
                .then(response => {
                    this.loader = false;
                    this.showAlert = true;
                    if (response.status === 200) {
                        this.editMode = false;
                        this.isSuccess = true;
                        this.responseMsg = response.data.message;
                    } else {
                        this.isSuccess = false;
                        this.responseMsg = response.response.data.message;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    this.showAlert = true;
                    this.isSuccess = false;
                    this.responseMsg = appStrings.autoFailedMsg;
                });
        },
        clear() {
            this.inventoryOrg = '';
            this.getMappingInvItemDetails();
        }
    },
    beforeDestroy() {
        this.unsubscribe();
    }
};